<script setup>

import { ref } from "vue";
import HelloWorld from './components/HelloWorld.vue'
import QuestionPage from './components/QuestionPage.vue'
import SevenUps from './components/SevenUps.vue'
import M3U8 from './components/M3U8.vue'
import M3u8Pro from './components/M3u8Pro.vue'
import ShortVideo from "./components/ShortVideo.vue"

const showOptions = ref("vedio");
console.log(showOptions.value)

const buttomChoose = ref([
    { part: "left", content: "测试一:初始内容" },
    { part: "middle", content: "测试二:网络请求" },
    { part: "vedio", content: "测试三:视频播放" },
    { part: "right", content: "测试四:模板语法学习" },
    {part:"shortvideo",content:"爽剧来袭"},
])

const changeTest = (val) => {
    showOptions.value = val
}

</script>

<template>
    <div class="container">
        <div class="buttomChange">
            <button class="buttonChangeTest" v-for="(item, index) in buttomChoose" @click="changeTest(item.part)">{{
                item.content
            }}</button>
        </div>

        <div class="left" v-show="showOptions == 'left'">
            <p>左侧内容</p>
            <a href="https://vitejs.dev" target="_blank">
                <img src="/vite.svg" class="logo" alt="Vite logo" />
            </a>
            <a href="https://vuejs.org/" target="_blank">
                <img src="./assets/vue.svg" class="logo vue" alt="Vue logo" />
            </a>
            <HelloWorld msg="Vite + Vue" />
        </div>

        <div class="middle" v-show="showOptions == 'middle'">
            <div class="question">
                问答HTTP POST请求<br />
                <QuestionPage msg="小明 & 小强 艰难行进中..." />
            </div>
        </div>

        <div class="vedio" v-show="showOptions == 'vedio'">
            <!-- <div class="vbox">
                <M3U8></M3U8>
            </div> -->
            <div class="vbox">
                <M3u8Pro></M3u8Pro>
            </div>
        </div>

        <div class="right" v-show="showOptions == 'right'">
            中间内容
            <SevenUps></SevenUps>
        </div>

        <div class="shortvideo" v-show="showOptions == 'shortvideo'">
            <ShortVideo></ShortVideo>
        </div>
        

    </div>
</template>

<style lang="scss" scoped>
.container {
    display: flex;
    /* 也可使用Grid布局 */
    flex-direction: column;
    /*  row-reverse;  column */
    justify-content: space-between;

    .buttomChange {
        display: flex;
        /* padding: 5px; */
        /* border: 1px solid green; */
        justify-content: space-around;

        .buttonChangeTest {
            padding: 5px;
            /* margin: 10px; */
            border: 1px solid red;
            font-size: 12px;
            font-weight: bold;
        }
    }

    .left {
        flex: 1;
        /* 左侧内容自动填充剩余空间 */
        background-color: rgb(232, 153, 169);

        .logo {
            height: 3em;
            padding: 1.5em;
            will-change: filter;
            transition: filter 300ms;
        }

        .logo:hover {
            filter: drop-shadow(0 0 2em #646cffaa);
        }

        .logo.vue:hover {
            filter: drop-shadow(0 0 2em #42b883aa);
        }
    }

    .middle {
        flex: 1;
        background-color: aquamarine;

        .question {
            background-color: beige;
        }
    }

    .right {
        flex: 1;
        /* 右侧内容自动填充剩余空间 */
        background-color: rgb(219, 212, 244);
    }

    .vedio {
        display: flex;
        flex-direction: row;

        .vbox {
            padding: 10px 10px;
            // border: 1px solid blue;
            background: rgba(100, 100, 100, 0.3);
            margin: 10px auto;
        }
    }
}
</style>

<template>
  <div>模板语法学习</div>

  <!-- 文本插值-->
  <span>Message: {{ msg }}</span>

  <!-- 原始 HTML-->
  <p>Using text interpolation: {{ rawHtml }}</p>
  <p>Using v-html directive: <span v-html="rawHtml"></span></p>

  <!-- Attribute 绑定 -->
  <div v-bind:id="dynamicId">测试Attribute</div>
  <!-- 或简写 -->
  <div :class="dynamicClass">测试Attribute</div>

  <!-- 布尔型 Attribute -->
  <button :disabled="isButtonDisabled">Button</button>

  <!-- 动态绑定多个值 -->
  <div v-bind="objectOfAttrs"></div>

  <!-- 使用 JavaScript 表达式:  Vue 实际上在所有的数据绑定中都支持完整的 JavaScript 表达式 -->
  <div>
    <!-- JavaScript 表达式可以被使用在如下场景上：
				在文本插值中 (双大括号)
				在任何 Vue 指令 (以 v- 开头的特殊 attribute) attribute 的值中
		-->
    {{ number + 1 }} |

    {{ ok ? 'YES' : 'NO' }} |

    {{ message.split('').reverse().join('') }}

    <div :id="`list-${id}`">南天门list-1样式</div>
  </div>

  <p v-if="seen">Now you see me..</p>

  <!-- 指令参数: 某些指令会需要一个“参数”，在指令名后通过一个冒号隔开做标识 -->
  <div>
    <a v-bind:href="url"> 跳转百度网 </a>

    <!-- 简写 -->
    <a :href="url"> 跳转百度网 </a>

    <a v-on:click="increment"> 当前值为: {{ count }} </a>

    <!-- 简写 -->
    <a @click="doSomething"> ... </a>
  </div>
</template>

<script>
export default {
  // Vue 将在创建新组件实例的时候调用此函数，并将函数返回的对象用响应式系统进行包装
  // 这些实例上的属性仅在实例首次创建时被添加
  data() {
    return {
      count: 0,
      msg: 'Welcome Here to Say Hi.',
      rawHtml: '<span style="color: red">This should be red.</span>',
      dynamicId: 'id_selector',
      dynamicClass: 'class_selector',
      isButtonDisabled: true,
      objectOfAttrs: {
        id: 'container', // 优先匹配id
        class: 'wrapper'
      },
      number: 328,
      ok: true,
      message: '恐怖如斯',
      id: 1,
      seen: false,
      url: 'https://www.baidu.com'
    }
  },
  // 为组件添加方法
  methods: {
    increment() {
      // Vue 自动为 methods 中的方法绑定了永远指向组件实例的 this
      this.count++
    }
  },
  // mounted 钩子可以用来在组件完成初始渲染并创建 DOM 节点后运行代码
  mounted() {
    console.log('the component is now mounted.')
  }
}
</script>

<style>
#id_selector {
  width: 500px;
  height: 50px;
  font-size: 1cm;
  color: red;
}

.class_selector {
  width: 500px;
  height: 50px;
  font-size: 1cm;
  color: darkblue;
}

#list-1 {
  font-size: 1cm;
  color: blue;
}
</style>

export async function request(config={}){
	// 解构请求参数
	let { 
		url,
		data={},
		header={"Access-Control-Allow-Origin":"*"},
		method="GET"
	 } = config
	const rsp = await fetch(url,{
            method,
            header,
            mode:'cors',
            credentials:'omit',
            data
        })
	const res = await rsp.json();
	return res;

}


// return new Promise((resolve,reject)=>{
// 	uni.request({
// 		url,
// 		method,
// 		header,
// 		data,
// 		success:res=>{
// 			console.log(res);
// 			if(res.data.status == "0"){
// 				resolve(res.data.data)
// 			}else{
// 				uni.showToast({
// 					title:res.data.mesage,
// 					icon:"none"
// 				})
// 				reject(res.data.data)
// 			}
			
// 		},
// 		fail:err=>{
// 			reject(err)
// 		}
// 	})
// })
<!-- 参考文章: https://blog.csdn.net/qq_36410795/article/details/107109514 -->
<template>
    <div class="m3u8pro">
        <div class="videoBlk">
            <video ref="videoPlayer" id="videoPlayer2" class="video-js vjs-default-skin"></video>
        </div>

        <div class="buttonChange">
            <button @click="changeSource">切换到下一个视频</button>
        </div>

        <div>
            <div>当前视频信息</div>
            <div>名称: {{ playlist[curVideoIdx].vod_name  }}</div>
            <div>简介: {{ playlist[curVideoIdx].vod_content  }}</div>
        </div>

    </div>
</template>
<script>
import Videojs from "video.js"; // 引入Videojs
// import "videojs-contrib-hls";
export default {
    data() {
        return {
            playlist:[
                {
                    "vod_name": "冰雪狙击",
                    "vod_pic": "https://pic1.imgyzzy.com/upload/vod/2022-03-20/202203201647766949.jpg",
                    "vod_content": "　　东北青年华志是一名东北军士兵，九一八事变后曾与日寇血战。东北沦陷，他对时局失望，返回山林，以打猎为生。一九四一年冬，华志救下遭到日寇伏击的抗联小分队，导致他的妻儿和乡亲都被日寇杀害。为了报国恨家仇，他加入抗联，发挥神枪手的作用，跟日寇从太平洋战场上抽调的两位顶级狙击手生死对狙。敌强我弱，步步杀机。为了阻止日寇向南方运送军火，他又毅然跟随抗联小分队潜入日军军火库，与守敌浴血奋战，光荣地完成使命。",
                    "vod_play_url": "https://yzzy.play-cdn21.com/20240407/22188_f2e7a5df/index.m3u8",
                },
                {
                    "vod_name": "暴走财神",
                    "vod_pic": "https://pic1.imgyzzy.com/upload/vod/2022-03-20/202203201647775107.jpg",
                    "vod_content":"　　影片主要讲述了一个市井混混王嘉诚，宿醉后梦见财神显灵，醒来后竟真的一夜暴富。面对巨额财富，他挥霍迷失，急剧膨胀，招来社会人的纠缠追杀，不仅财富不翼而飞，自己也深陷多重危机。", 
                    "vod_play_url": "https://yzzy.play-cdn21.com/20240407/22185_e3d8bbc6/index.m3u8",
                },
                {
                    "vod_name": "暴走财神2",
                    "vod_pic": "https://pic1.imgyzzy.com/upload/vod/2022-03-20/202203201647775196.jpg",
                    "vod_content": "　　被生活不断暴击的房产推销员王嘉诚，在天台竟遇到财神！不小心踹碎了财神的法器被其碰瓷，摆在王嘉诚面前两条路：修好法器荣华富贵，不修法器五雷轰顶！于是一人一神的奇幻之旅爆笑走起！",
                    "vod_play_url": "https://yzzy.play-cdn21.com/20240407/22182_af3dbbac/index.m3u8",
                },
                {
                    "vod_name": "暴走财神3",
                    "vod_pic": "https://pic1.imgyzzy.com/upload/vod/2022-03-20/202203201647775235.jpg",
                    "vod_content": "　　房产推销员王嘉诚，偶遇了财神，并且还踩碎了他的法器，如能修好法器，财神将会赏他荣华富贵。一夜暴富的穷小子，在经历了一连串因钱引发的啼笑皆非的荒唐事后，逐渐找回迷失的自我，领悟了人生的意义。",
                    "vod_play_url": "https://yzzy.play-cdn21.com/20240407/22179_ecf2a91e/index.m3u8",
                },
                {
                    "vod_name": "不老山异事",
                    "vod_pic": "https://pic1.imgyzzy.com/upload/vod/2022-03-20/202203201647775296.jpg",
                    "vod_content": "　　前朝太后一世追求长生之术，后留《十二长生赋》传说于世引各方争抢。民国年间，黄庙村中李老爷一生行善却暴毙家中，头七之日，枯井生水怨鬼还魂，坊间传言其为长生之术反噬。为查真相，民间异士林道长行奇门遁甲之术，卜易经八卦之诀，不老山异事正待被揭开谜底……",
                    "vod_play_url": "https://yzzy.play-cdn17.com/20230720/11745_f1d84339/index.m3u8",
                }
            ],
            curVideoIdx:1,
            options: {
                autoplay: true, // 设置自动播放
                muted: false, // 设置了它为true，才可实现自动播放,同时视频也被静音 （Chrome66及以上版本，禁止音视频的自动播放）
                preload: "auto", // 预加载
                controls: true, // 显示播放的控件
            },
            player: null
        };
    },
    mounted() {
        this.changeVideo();
    },
    methods: {
        changeVideo() {
            let v_url = this.playlist[this.curVideoIdx].vod_play_url;
            let v_pic = this.playlist[this.curVideoIdx].vod_pic;
            console.log("---->", v_url, v_pic);
            if (this.player == null) {
                this.options.poster = v_pic;
                this.options.sources = [
                    {
                        src: v_url,
                        type: "application/x-mpegURL"
                    }
                ],
                this.player = Videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
                    Videojs.log('Your player is ready!');

                    // In this context, `this` is the player that was created by Video.js.
                    // this.play();

                    // How about an event listener?
                    this.on('ended', function () {
                        Videojs.log('Awww...over so soon?!');
                    });
                });
                Videojs.getPlayer(this.$refs.videoPlayer).src([
                    {
                        src: v_url,
                        type: "application/x-mpegURL"
                    }
                ]);

            } else {
                // 此处切换必须使用 vedio.js 自己的api才可以
                // refs: https://cloud.tencent.com/developer/ask/sof/107421794
                let curplayer =  Videojs.getPlayer(this.$refs.videoPlayer);
                curplayer.poster(v_pic);
                curplayer.src([
                    {
                        src: v_url,
                        type: "application/x-mpegURL" // 告诉videojs,这是一个hls流
                    }
                ]);
            }
            console.log(this.player.src());
        },
        changeSource() {
            console.log(this.curVideoIdx);
            this.curVideoIdx = (this.curVideoIdx + 1) % this.playlist.length
            console.log(this.curVideoIdx, "改变了");
            this.changeVideo();
        }
    },
    // watch: {
    //     nowPlayVideoUrl(newVal, old) {
    //         console.log("nowPlayUrl change from", old, "to ", newVal);
    //         this.changeVideo();
            
    //     }
    // },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose(); // Removing Players,该方法会重置videojs的内部状态并移除dom
        }
    }
};
</script>

<style lang="scss" scoped>
.m3u8pro {
    display: flex;
    flex-direction: column;

    .buttonChange {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .videoBlk {
        #videoPlayer2 {
            width: 40vw;
            height: 400px;
            margin: 50px 50px;
        }
    }

}
</style>
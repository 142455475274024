<template>
    <h3>短剧大全</h3>
    <div class="m3u8pro">
        <div class="videoBlk">
            <video ref="videoPlayer" id="videoPlayer2" class="video-js vjs-default-skin"></video>
        </div>

        <div v-for="item in playlist" :key="item.vod_id">
            <div>名称: {{ item.vod_name }}</div>
            <div>简介: {{ item.vod_content  }}</div>
        </div>


    </div>
</template>
  


<script setup>
import { ref } from "vue";
import {apiGetVideos} from '../api/apis';

const playlist = ref([])

const getVideos = async ()=>{
    console.log("before requst...");
    let res = await apiGetVideos();
    console.log("after requst...");
    console.log("-->data:", res);
    playlist.value = res.list;
}
getVideos();

// const testNet = async ()=>{
//   const response = await fetch("/videourl/inc/api_mac10.php");
//   const movies = await response.json();
//   console.log(movies);
//   playlist.value = movies.list;
// }
// testNet();

</script>

<style lang="scss" scoped>


</style>
../api/apis
<template>
    <div>
        <p>{{ msg }}</p>
    </div>
    <button @click="count++">click times {{ count }}</button>
    <div>
        <p>
            Ask a yes/no question:
            <input v-model="question" />
        </p>
        <p>提示:必须使用英文的?作为结尾</p>
        <p>answer:{{ answer }}</p>
        <p>rsp json:{{ json_rsp }}</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            count: 0,
            question: '',
            answer: 'Questions usually contain a question mark. ;-)',
            json_rsp: ''
        }
    },
    props: ['msg'],
    watch: {
        // 每当 question 改变时，这个函数就会执行
        question(newQuestion, oldQuestion) {
            if (newQuestion.includes('?')) {
                this.getAnswer(newQuestion)
            }
        }
    },
    methods: {
        async getAnswer(data) {
            this.answer = 'Thinking...'
            console.log("begin...")
            try {
                // 请求形式如下：[https://developer.mozilla.org/zh-CN/docs/Web/API/Fetch_API/Using_Fetch] 
                const response = await fetch('https://yesno.wtf/api', {
                    method: 'GET',
                    // headers: {
                    //   'Content-Type': 'application/json',
                    // },
                    mode: 'cors',
                    credentials: 'omit',
                    // body: JSON.stringify(data),
                });
                console.log(response)
                const responseData = await response.json();
                this.json_rsp = responseData
                this.answer = responseData.answer;
            } catch (error) {
                console.error(error);  // 'Error:',
                this.answer = 'Error occurred while fetching data.';
            }
            console.log("end..1.")
        }
        // 问答页面原始请求：
        // const res = await fetch('https://yesno.wtf/api')
        /* 返回结果如下：GIF图片随机
        {
            "answer": "yes",
            "forced": false,
            "image": "https://yesno.wtf/assets/yes/14-b57c6dc03aa15a4b18f53eb50d6197ee.gif"
        }
        */


    }
}
</script>

<style></style>

import {request} from "../utils/request"

const yzzy_api_url = "/videourl/inc/api_mac10.php";

export function apiGetVideos(data={}){
	return request({
		url: yzzy_api_url,
        method:"GET",
		data:data
	})
}